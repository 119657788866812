import React from 'react'
import Layout from '../components/Layout'

export default function about() {
    return (
        <Layout>
            <div className=''>
                <h1>About Us</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi quod mollitia eius minus, dolorem molestiae in voluptate vitae dolor. Ipsam quae maiores aut fugiat dignissimos quisquam doloremque id, rem in quod, voluptates velit aliquid, at laudantium! Vel quam officia quasi sunt, totam, nisi minima illum aut distinctio facere, cupiditate odio!</p>
                <h1>Team Members</h1>
                <h3>JeMimiWatts</h3>
                <p>President & Director of Jelly Fox Studios. Head of Writing & Lead Casting Director.</p>
                <h3>Dark Sharingan Z</h3>
                <p>Vice president & Head Video Editor. Assists with casting & resource management.</p>
                <h3>Thomas of the 14th</h3>
                <p>Head of Sound Effects. Also provides skill with video editing and meeting organization.</p>
                <h3>Orphen</h3>
                <p>Head of Music Department</p>
                <h3>Zoraxe</h3>
                <p>Head of 3D art. Focused on background creation.</p>
                <br />
                <h1>Mission Statement</h1>
                <p>We will do all we can to get the Unknown to be known! To give new content creators a way to get their material out ther in a reputable and organized fashion. By combining the talents of these talented individuals, our hope is that we will be able to help them get noticed and that their true potential gets realized!</p>
                <p>We will not hold any exclusive rights as a company over any works submitted but reserve the right to retain copies should anything happen to the final product where it needs restored or fixing.</p>
            </div>
        </Layout>
    )
}
